import styled from 'styled-components'

export const HeroContent = styled.div`
  font-size: ${({ theme }) => theme.font.size.billboard};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => `calc(100vh - (${theme.navigation.height}px * 2))`};
`
