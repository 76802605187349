import { Typography } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useTrackNavigationEvent, CurrentPathTypeEnum } from '@modules/analytics'

import * as S from './NotFoundPage.styles'

const NotFoundPage: React.FC = () => {
  useTrackNavigationEvent({ currentPathType: CurrentPathTypeEnum.PageNotFound })

  return (
    <S.PageWrapper>
      <S.HeroContent>
        4<FiberManualRecordIcon fontSize="large" />4
      </S.HeroContent>
      <Typography>Page Not Found</Typography>
    </S.PageWrapper>
  )
}

export default NotFoundPage
